import {AuthenticationContext, withAdalLogin} from 'react-adal';
import { isNil } from 'lodash';
import {Cache} from "aws-amplify";

const { REACT_APP_REDIRECT_URI, REACT_APP_AZURE_CLIENT_ID, REACT_APP_AZURE_API } = process.env;

export const adalConfig = {
    tenant: 'woodsideenergy.onmicrosoft.com',
    clientId: REACT_APP_AZURE_CLIENT_ID,
    endpoints: {
        api: REACT_APP_AZURE_API,
    },
    redirectUri: REACT_APP_REDIRECT_URI,
};
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getToken = async () => new Promise(async (resolve) => {
    let theToken = null;
    let theError = null;
    let i = 0;
    const MAX_RETRIES = 10;
    const WAIT_RETRY = 1000; // 1 second wait retry
    const assignToken = (error: string, token: string) => {
        Cache.setItem('federatedInfo', {token:authContext.getCachedToken(adalConfig.endpoints.api)});
        theToken = token;
        theError = error;
    };

    while (isNil(theToken) && i < MAX_RETRIES) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(WAIT_RETRY);
        // @ts-ignore
        authContext.acquireToken(adalConfig.endpoints.api, assignToken);
        i += 1;
    }

    if (!isNil(theToken)) {
        resolve(theToken);
    } else {
        console.log(theError);
    }
});

// @ts-ignore
export const authContext = new AuthenticationContext(adalConfig);

// @ts-ignore
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
