import gql from 'graphql-tag';

const shareVideoStream = gql`
    mutation ShareVideoStream($streamName: String!, $passphrase: String!, $startDate:AWSTimestamp, $endDate:AWSTimestamp) {
        shareVideoStream(streamName: $streamName, passphrase: $passphrase, startDate: $startDate, endDate: $endDate) {
            streamName
            videoId
        }
    }`;

const removeVideoStream = gql`
    mutation RemoveVideoStream($videoId: String!) {
        removeVideoStream(videoId: $videoId) {
            streamName
            videoId
        }
    }`;

export {
    shareVideoStream,
    removeVideoStream
}
