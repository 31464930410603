import gql from 'graphql-tag';

const addedVideoStream = gql`
    subscription {
        addedVideoStream {
            streamName
            videoId
        }
    }`;

const removedVideoStream = gql`
    subscription {
        deletedVideoStream {
            streamName
            videoId
        }
    }`;

export {
    addedVideoStream,
    removedVideoStream
}
