import * as React from "react";
import {useState} from "react";
import {createStyles, Dialog, DialogContentText, Theme} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";
import {getVideoStreamLink} from "../graphql/queries";
// @ts-ignore
import ReactHLS from 'react-hls-player';
import {Alert, AlertTitle} from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 150,
            // padding: theme.spacing(2),
            margin: 10
        },
        control: {
            padding: theme.spacing(2),
        },
        media: {
            width: "auto",
        },
        loading: {
            height: '768px' ,
            objectFit: 'cover',
            width: '576px',
        },
    }),
);

// @ts-ignore
export function ViewLiveVideo(props) {
    const classes = useStyles();

    const handleSubmit = async () => {
        setDialogState({...dialogState, open: false});
        await fetchLiveStream(props.match.params.videoId, dialogState.passphrase);

    };

    const handleChange = (event: any) => {
        setDialogState({...dialogState, passphrase: event.target.value});
    };

    const [dialogState, setDialogState] = useState({open: true, passphrase: ""});
    const [stream, setStream] = useState({streamName: '', liveUrl: ''});
    const [error, setErrorMessage] = useState({open: false, message: ''});


    async function fetchLiveStream(videoId: string, passphrase: string) {
        try {
            const stream = await (API.graphql(
                {
                    query: getVideoStreamLink,
                    variables: {videoId, passphrase},
                    authMode: GRAPHQL_AUTH_MODE.OPENID_CONNECT
                }));
            // @ts-ignore
            setStream(stream.data.getVideoStreamLink);
        } catch (err) {
            setErrorMessage({open: true, message: err.errors[0].message});
            console.log(`error fetching livestream : ${err.errors[0].message}`);
        }
    }

    // @ts-ignore
    return (
        <React.Fragment>
            <Collapse in={error.open}>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error.message}
                </Alert>
            </Collapse>
            {dialogState.open &&
            <Dialog open={dialogState.open} onClose={handleSubmit} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Live Stream</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To view your video please enter the passphrase that was given to you
                    </DialogContentText>
                    <TextField
                        onChange={handleChange}
                        autoFocus
                        id="passphrase"
                        label="Passphrase"
                        type="password"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            }
            {
                !dialogState.open && !error.open && stream.liveUrl === "" &&
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.loading}
                            component="img"
                            image="/loading.gif"
                            title="Live Video"
                        />
                        </CardActionArea>
                </Card>
            }
            {
                !dialogState.open && !error.open && stream.liveUrl !== "" &&
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardMedia className={classes.media} title="Live Video">
                            <ReactHLS width="auto" height="auto" url={stream.liveUrl} hlsConfig={{'enableWorker': false}} autoplay={true}/>
                        </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {stream.streamName}
                            </Typography>
                        </CardContent>
                        </CardActionArea>
                </Card>
            }
        </React.Fragment>
    );
}
