import * as React from "react";
import {useEffect, useState} from "react";
import {API, graphqlOperation} from 'aws-amplify'
import {getVideoStreams} from "../graphql/queries";
import MediaControlCard from "../components/MediaControlCard";
import {createStyles, Theme} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getToken} from "../adalConfig";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {addedVideoStream, removedVideoStream} from "../graphql/subscription";
import Collapse from "@material-ui/core/Collapse/Collapse";
import {Alert, AlertTitle} from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        root: {
            flexGrow: 1,
            marginTop: 4
        },
        paper: {
            height: 140,
            width: 100,
        },
        control: {
            padding: theme.spacing(2),
        },
    }),
);

export function ViewVideoStreams() {
    const classes = useStyles();
    const [streams, setStreams] = useState([]);
    const [backdropOpen, setBackdrop] = useState(false);
    const [error, setErrorMessage] = useState({open: false, message: ''});
    // @ts-ignore
    useEffect(() => {
        fetchLiveStreams();
        subscribeToVideoStream();
    }, []);

    async function fetchLiveStreams() {
        try {
            setBackdrop(true);
            await getToken();
            const streams = await (API.graphql(graphqlOperation(getVideoStreams)));
            // @ts-ignore
            setStreams(streams.data.getVideoStreams);
            let numOfError = 0;

            // @ts-ignore
            streams.data.getVideoStreams.forEach(o =>{
                if (o.liveUrl == null ) {
                    numOfError ++;
                }
            });

            // @ts-ignore
            if (numOfError > 0 && numOfError === streams.data.getVideoStreams.length) {
                setErrorMessage({open:true, message: "The FUSE video streaming application is currently experiencing issues. Please contact the Intelligent Systems Technology team."});
            } else if( numOfError > 0) {
                setErrorMessage({open:true, message: "Some of the video stream(s) are currently down."});
            }

        } catch (err) {
            console.log('error fetching livestreams' + err)
        }
        setBackdrop(false);
    }

    async function subscribeToVideoStream() {
        await getToken();
        // @ts-ignore
        API.graphql(graphqlOperation(addedVideoStream)).subscribe({
            next: fetchLiveStreams
        });

        // @ts-ignore
        API.graphql(graphqlOperation(removedVideoStream)).subscribe({
            next: fetchLiveStreams
        });
    }

    return (
        <React.Fragment>
            <Collapse in={error.open}>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error.message}
                </Alert>
            </Collapse>
            <Backdrop className={classes.backdrop} open={backdropOpen}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={3}>
                        {
                            streams.map(s => {
                                // @ts-ignore
                                return <MediaControlCard {...s} key={s.streamName}/>
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>

    );
}
