import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import getUnixTime from 'date-fns/getUnixTime'
import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
// @ts-ignore
import ReactHLS from 'react-hls-player';
import Button from '@material-ui/core/Button';
import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";
import {removeVideoStream, shareVideoStream as shareVideoStreamsGQL} from "../graphql/mutations";
import {Grid, Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';

const randomstring = require("randomstring");

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        root: {
            minWidth: 150,
            // padding: theme.spacing(2),
            margin: 10
        },
        control: {
            padding: theme.spacing(2),
        },
        media: {
            width: 'auto'
        },
        error: {
            width: 'auto'
        },
    }),
);

export interface MediaControlCardInterface {
    streamName: string
    liveUrl: string
    videoShares: [any]
}

export default function MediaControlCard(props: MediaControlCardInterface) {
    async function share() {
        await shareVideoStream(props.streamName, getUnixTime(fromDate!), getUnixTime(toDate!))
    }

    const handleFromDateChange = (date: Date | null) => {
        setFromDate(date);
    };

    const handleToDateChange = (date: Date | null) => {
        setToDate(date);
    };

    async function deleteVideoStream(videoId: string) {
        await (API.graphql(
            {
                query: removeVideoStream,
                variables: {videoId},
                authMode: GRAPHQL_AUTH_MODE.OPENID_CONNECT
            }));
    }

    async function shareVideoStream(streamName: string, startDate: number, endDate: number) {

        const {href} = window.location;

        const passphrase = randomstring.generate();
        const stream = await (API.graphql(
            {
                query: shareVideoStreamsGQL,
                variables: {streamName: props.streamName, passphrase, startDate, endDate},
                authMode: GRAPHQL_AUTH_MODE.OPENID_CONNECT
            }));
        const viewLiveVideoUrl = href.replace("view-video-streams", "");
        // @ts-ignore
        setShareResult({
            open: true,
            passphrase,
            // @ts-ignore
            url: `${viewLiveVideoUrl}view-live-video/${stream.data.shareVideoStream.videoId}`
        });
    }

    const [shareResult, setShareResult] = useState({url: "", passphrase: "", open: false, hide: true});

    const handleClose = () => {
        setShareResult({...shareResult, open: false});
    };

    const classes = useStyles();
    const [fromDate, setFromDate] = React.useState<Date | null>(
        new Date(),
    );

    const [toDate, setToDate] = React.useState<Date | null>(
        new Date(),
    );

    return (
        <React.Fragment>

            <Dialog
                open={shareResult.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Share this with customer"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Url is {shareResult.url} and passphrase is {shareResult.passphrase}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Card className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Shares</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <List>
                            {props.videoShares && props.videoShares.map((vid) => {
                                return (
                                    <ListItem key={vid.videoId}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FolderIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            secondary={new Date(vid.endDate * 1000).toUTCString()}
                                            primary={new Date(vid.startDate * 1000).toUTCString()}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete"
                                                        onClick={async () => {
                                                            await deleteVideoStream(vid.videoId)
                                                        }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <CardActionArea>
                    {
                        props.liveUrl && props.liveUrl !== "" ?
                            <CardMedia
                                className={classes.media}
                                title="Live Video"
                            >
                                <ReactHLS width="auto" height="auto" url={props.liveUrl} hlsConfig={{'enableWorker': false}} autoplay={false}/>
                            </CardMedia> :
                            <CardMedia
                                className={classes.error}
                                component="img"
                                image="/stream-error.png"
                                title="Live Video"
                            />
                    }
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {props.streamName}
                        </Typography>


                    </CardContent>
                </CardActionArea>

                <CardActions>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around" direction="column">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="From Date"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </Grid>
                        <Grid container justify="space-around" direction="column">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="To Date"
                                value={toDate}
                                onChange={handleToDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time"
                                value={toDate}
                                onChange={handleToDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </Grid>
                        <Button size="small" color="primary" onClick={share}>
                            Share
                        </Button>
                    </MuiPickersUtilsProvider>
                </CardActions>
            </Card>
        </React.Fragment>
    );
}
