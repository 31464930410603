import gql from 'graphql-tag';

const getVideoStreams = gql`
    query {
        getVideoStreams {
            streamName
            liveUrl
            videoShares {
                videoId
                startDate
                endDate
            }
        }
    }`;

const getVideoStreamLink = gql`
    query GetVideoStreamLink($videoId: String!, $passphrase: String!) {
        getVideoStreamLink(videoId: $videoId, passphrase: $passphrase) {
            streamName
            liveUrl
            
        }
    }`;

export {
    getVideoStreams,
    getVideoStreamLink
}
