import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import 'typeface-roboto';
import {ViewVideoStreams} from "./pages/ViewVideoStreams";
import {withAdalLoginApi} from "./adalConfig";
import {ViewLiveVideo} from "./pages/ViewLiveVideo";

const {REACT_APP_APPSYNC_ENDPOINT} = process.env;
const REGION = 'ap-southeast-2';

Amplify.configure({
    'aws_appsync_graphqlEndpoint': REACT_APP_APPSYNC_ENDPOINT,
    'aws_appsync_region': REGION,
    'aws_appsync_authenticationType': 'OPENID_CONNECT' // You have
});

// @ts-ignore
const ProtectedViewVideoStreams = withAdalLoginApi(ViewVideoStreams, null, null);

function App() {

    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/view-live-video/:videoId" component={ViewLiveVideo}/>
                    <Route path="/">
                        <ProtectedViewVideoStreams/>
                    </Route>
                    <Route path="/view-video-streams">
                        <ProtectedViewVideoStreams/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
